const translations = {
    "account-detail.save-notification-settings": "Save notification settings",
    "account-details.tab.basicinfo": "Basic info",
    "account-details.tab.crm": "CRM info",
    "account-details.tab.equipment": "Equipment",
    "account-details.tab.visithistory": "Visit History",
    "account-details.tab.workorder": "Work orders",
    "account-details.tab.workorders": "Work Orders",
    "account-report.complaints.date": "Date",
    "account-report.complaints.number": "Number",
    "account-report.complaints.reason": "Complaint Reason",
    "account-report.complaints.subject": "Subject",
    "account-report.complaints.type": "Complaint type",
    "account-report.customer-visits.customer-contact": "Customer contact",
    "account-report.customer-visits.date": "Date",
    "account-report.customer-visits.subject": "Subject",
    "account-report.customer-visits.visitor": "Visitor",
    "account-report.equipment.address": "Address",
    "account-report.equipment.callouts": "Callouts",
    "account-report.equipment.completed": "Completed",
    "account-report.equipment.link": "View report",
    "account-report.equipment.missed-visits": "Completed work orders",
    "account-report.equipment.number": "Number",
    "account-report.equipment.open-mbms": "Open MBMs",
    "account-report.equipment.open-service-orders": "Open work orders",
    "account-report.equipment.ordersummary": "Equipment Orders Summary",
    "account-report.equipment.other-service-orders": "Other work orders",
    "account-report.equipment.out-of-order": "Out of order",
    "account-report.equipment.response.time": "Response Time",
    "account-report.equipment.response-time": "Response time",
    "account-report.equipment.service-time": "Service time",
    "account-report.equipment.summary": "Equipment Summary",
    "account-report.header.created": "Created",
    "account-report.header.customer-report": "Customer report for",
    "account-report.invoices.customer-po": "Customer PO",
    "account-report.invoices.date": "Invoice date",
    "account-report.invoices.document-type": "Document type",
    "account-report.invoices.number": "Invoice number",
    "account-report.leads.business-type": "Business Type",
    "account-report.leads.description": "Description",
    "account-report.leads.owner": "Lead owner",
    "account-report.leads.source": "Source",
    "account-report.leads.stage": "Stage",
    "account-report.leads.status": "Status",
    "account-report.no-entries": "No entries",
    "account-report.not-available": "N/A",
    "account-report.notes.content": "Content",
    "account-report.opportunities.amount": "Amount",
    "account-report.opportunities.business-type": "Business type",
    "account-report.opportunities.description": "Description",
    "account-report.opportunities.name": "Name",
    "account-report.opportunities.number": "Number",
    "account-report.opportunities.stage-name": "Stage",
    "account-report.queries.category": "Query category",
    "account-report.queries.date": "Date",
    "account-report.queries.number": "Number",
    "account-report.queries.reason": "Query reason",
    "account-report.queries.subject": "Subject",
    "account-report.sections.account-information": "Account information",
    "account-report.sections.account-owner": "Account owner",
    "account-report.sections.complaints": "Last 10 Complaints",
    "account-report.sections.complaints (Showing last 10)": "Last 10 Complaints",
    "account-report.sections.contracts": "Contracts",
    "account-report.sections.customer-and-contract": "Customer & contract",
    "account-report.sections.customer-visits": "Customer visit history",
    "account-report.sections.equipment-and-operations": "Equipment & operations",
    "account-report.sections.equipment-info": "Equipment info",
    "account-report.sections.equipments": "Equipment",
    "account-report.sections.leads": "Open Leads",
    "account-report.sections.notes": "Last 10 Notes",
    "account-report.sections.notes (Showing last 10)": "Last 10 Section Notes",
    "account-report.sections.opportunities": "Opportunities Modified in last 12 months",
    "account-report.sections.opportunities (Modified last 12 months)": "Opportunities (Modified last 12 months)",
    "account-report.sections.queries": "Last 10 Queries",
    "account-report.sections.queries (Showing last 10)": "Last 10 Queries",
    "account-report.sections.service-orders": "Work orders for last 12 months",
    "account-report.sections.surveys": "Customer surveys",
    "account-report.sections.technicians": "Technicians",
    "account-report.sections.tenders": "Open tenders",
    "account-report.sections.transactional-surveys": "Transactional Survey",
    "account-report.sections.unpaid-invoices": "Unpaid due invoices",
    "account-report.surveys.contact": "Contact",
    "account-report.surveys.modification-date": "Modification date",
    "account-report.surveys.npi-score": "NPI score",
    "account-report.surveys.survey-name": "Flow survey name",
    "account-report.technicians.name": "Name",
    "account-report.technicians.workcenter": "Workcenter",
    "account-report.tenders.date": "Date",
    "account-report.tenders.description": "Description",
    "account-report.tenders.number": "Number",
    "account-report.tenders.owner": "Owner",
    "account-report.tenders.stage": "Stage",
    "account-report.tenders.total-sales-price": "Total sales price",
    "account-report.transactional-surveys.contact": "Contact",
    "account-report.transactional-surveys.name": "Survey ID",
    "account-report.transactional-surveys.npi-score": "NPI Score",
    "account-report.transactional-surveys.npi-verbatim": "NPI Verbatim",
    "account-report.transactional-surveys.response-received-date": "Response Received date",
    "account-report.transactional-surveys.survey-name": "Type",
    "account-reports.title": "Customer Report",
    "admin.admin-type.Global": "Global Admin",
    "admin.admin-type.Local": "Local Admin",
    "admin.company-settings.company-settings": "Company Settings",
    "admin.company-settings.menu-title": "Misc Settings",
    "admin.company-settings.table.company": "Company",
    "admin.company-settings.table.download": "Download Settings",
    "admin.company-settings.table.empty-column": "N/A",
    "admin.company-settings.table.last-modification": "Last modification",
    "admin.company-settings.table.modifiedBy": "Modified by",
    "admin.company-settings.table.no-entries": "No entries",
    "admin.company-settings.table.upload": "Upload Settings",
    "admin.company-settings.title": "Misc Settings",
    "admin.create-admin-assignment.admin-type.placeholder": "Type",
    "admin.create-admin-assignment.company.placeholder": "Company",
    "admin.create-admin-assignment.create-button": "Add User",
    "admin.create-admin-assignment.title": "New Kone SMT administrator",
    "admin.create-admin-assignment.user.placeholder": "User",
    "admin.health.menu-title": "Health Checks",
    "admin.health.sourcing": "Sourcing",
    "admin.health.table.country": "Country",
    "admin.health.table.server": "Server",
    "admin.health.table.source": "Source",
    "admin.health.table.status": "Status",
    "admin.health.vpn": "VPN",
    "admin.select-company.content": "Please select the Company you want to configure",
    "admin.select-company.title": "Select Company",
    "admin.upload-settings.incorrect-structure": "Incorrect Structure",
    "admin.upload-settings.title": "Upload Settings for company",
    "admin.user-management.administrators": "Administrators",
    "admin.user-management.admin-type.placeholder": "All types",
    "admin.user-management.confirm-remove.cancel": "CANCEL",
    "admin.user-management.confirm-remove.confirm": "REMOVE USER",
    "admin.user-management.confirm-remove.content": "After removing the user will no longer has access to the administration panel.",
    "admin.user-management.confirm-remove.title": "Remove user?",
    "admin.user-management.menu-title": "User management",
    "admin.user-management.select-company.placeholder": "All Companies",
    "admin.user-management.table.admin-type": "Admin Type",
    "admin.user-management.table.company": "Company",
    "admin.user-management.table.empty-column": "N/A",
    "admin.user-management.table.fullName": "Name",
    "admin.user-management.table.no-entries": "No entries",
    "admin.user-management.table.username": "Username",
    "admin.user-management.title": "User management",
    "admin.user-management.username.placeholder": "Search username",
    "admin-login-page.app-title": "Kone SMT Administration Panel",
    "admin-login-page.description.line-1": "Welcome to KONE SMT Administration Panel.",
    "admin-login-page.description.line-2": "In order to login in please use button below.",
    "admin-login-page.description.line-3": "You need to use your Salesforce credentials.",
    "admin-login-page.main-action-button": "Log in with Salesforce",
    "assembly-code.Call-in": "Call-in",
    "assembly-code.SN_CALLIN": "Call-in",
    "assembly-code.SN_REPRO": "Repro",
    "assembly-code.SN_STANDARD": "Standard",
    "assembly-code.SN_URGENT": "Urgent",
    "assembly-description.1st-visit-defect": "1st Visit Defect",
    "assembly-description.clinica": "Clinica",
    "assembly-description.express-letter": "Express Letter",
    "assembly-description.field-letter": "Field Letter",
    "assembly-description.other": "Other",
    "assembly-description.planned-service-repair": "Planned Service Repair",
    "automation-rule.activate-notifications": "Activate notifications",
    "automation-rule.activate-tasks": "Activate tasks",
    "btn_OK": "OK",
    "callout.field.assembly-code": "Assembly code",
    "callout.field.click-to-add-notes": "Click to add notes",
    "callout.field.notes": "Notes",
    "callout.field.work-center": "Work center",
    "complete-task-modal.mark-as-completed": "Mark as completed",
    "complete-task-modal.title": "Update Event description",
    "contract.field.building-manager-contact": "Building Manager Contact",
    "contract.field.end-date": "Contract end date",
    "contract.field.number": "Contract number",
    "contract.field.number-of-equipment": "# of equipment",
    "contract.field.response-time": "Normal hours response time",
    "contract.field.response-time-out-of-hours": "Out of hours response time",
    "contract.field.risk-profit-verbatim": "Profit Verbatim",
    "contract.field.risk-score": "Risk Score",
    "contract.field.risk-score-date": "Risk Score Date",
    "contract.field.risk-score-reason": "Risk Score Reason",
    "contract.field.service-time": "Service time",
    "contract.field.start-date": "Contract start date",
    "contract.field.type": "Contract type",
    "contract-status.active": "Active",
    "contract-status.expired": "Expired",
    "contract-status.future": "Future",
    "contract-status.inactive": "Inactive",
    "customer.report-callouts.completed": "callouts completed",
    "customer.report-other.open.orders": "Other Open Orders",
    "customer.report-other.orders.completed": "other orders completed",
    "equipment.field.next-mbm": "Next MBM",
    "equipment-details.crm.subheader.open-complaints": "Open complaints",
    "equipment-details.crm.subheader.open-opportunities": "Open opportunities",
    "equipment-details.crm.subheader.open-tenders": "Open tenders",
    "equipment-details.field.back-in-service": "Back in service",
    "equipment-details.field.billed-to-account": "Billed to account",
    "equipment-details.field.decided-by-account": "Decided by account",
    "equipment-details.field.equipment-id": "Equipment description",
    "equipment-details.field.equipment-type": "Equipment type",
    "equipment-details.field.last-service-order": "Last work order",
    "equipment-details.field.main-workcenter": "Main workcenter",
    "equipment-details.field.manufacturer-serial-number": "Manufacturer serial number",
    "equipment-details.field.market-segment": "Market segment",
    "equipment-details.field.number-of-floors": "Number of floors",
    "equipment-details.field.rated-load": "Load",
    "equipment-details.field.rated-load-imperial": "Rated load (imperial)",
    "equipment-details.field.rated-speed": "Rated speed",
    "equipment-details.field.rated-speed-imperial": "Rated speed (imperial)",
    "equipment-details.field.service-contract-characteristics": "Service Contract Characteristics",
    "equipment-details.field.site-address": "Site address",
    "equipment-details.field.sold-to-account": "Sold to account",
    "equipment-details.field.started-by": "Started by",
    "equipment-details.field.status": "Current operational status",
    "equipment-details.field.stop-date": "Stop date",
    "equipment-details.field.stopped-by": "Stopped by",
    "equipment-details.field.stop-reason": "Stop reason",
    "equipment-details.field.stop-reason-description": "Stop reason description",
    "equipment-details.field.technical-platform": "Technical platform name",
    "equipment-details.tab.accountandcontract": "Account & Contract",
    "equipment-details.tab.basicinfo": "Basic info",
    "equipment-details.tab.contacts": "Contacts",
    "equipment-details.tab.crminfo": "CRM info",
    "equipment-details.tab.helpdeskcases": "Helpdesk cases",
    "equipment-details.tab.serviceorders": "Work Orders",
    "equipment-details.tab.visithistory": "Visit History",
    "equipment-status.back-in-order": "Back in Order",
    "equipment-status.non-contracted": "Non contracted",
    "equipment-status.normal": "Normal Operation",
    "equipment-status.normal-operation": "Normal Operation",
    "equipment-status.out-of-order": "Out Of Order",
    "equipment-type.000": "Site00 - Building",
    "equipment-type.001": "Autowalk",
    "equipment-type.002": "Building Door",
    "equipment-type.003": "Dumbwaiter",
    "equipment-type.004": "Elevator",
    "equipment-type.005": "Escalator",
    "equipment-type.006": "Stairlift",
    "equipment-type.007": "Façadator",
    "equipment-type.008": "PFI",
    "equipment-type.010": "Marine Elevator",
    "equipment-type.011": "Marine Escalator",
    "equipment-type.012": "Marine Other Eq.",
    "equipment-type.999": "Other",
    "equipment-type.test": "Test",
    "error.excel-not-found": "Excel editor not found",
    "error.generic": "An error occured",
    "error.text-editor-not-found": "Text editor not found",
    "event-modal.cx-onboarding-visit": "CX Onboarding Visit",
    "event-modal.cx-site-visit": "CX Site Visit",
    "event-modal.empty-objective": "Empty Objective",
    "event-type.customer-event": "Customer event",
    "event-type.customer-visit": "Customer visit",
    "event-type.meeting-internal": "Meeting internal",
    "event-type.phone-visit": "Phone call",
    "event-type.prospecting-visit-cold-call": "Prospecting Visit (Cold Call)",
    "feed.group.entrapments": "Emergencies",
    "feed.group.overdue": "Overdue",
    "feed.group.without-date": "Others",
    "feed.tab.audits-with-event": "Audits with Events",
    "feed.tab.audits-without-event": "Audits Without Event",
    "feed.tab.completed": "Closed",
    "feed.tab.open": "Open",
    "feed.title-audits": "Audits",
    "feed.title-konect": "Operational Feed",
    "feed.title-my-tasks": "My tasks",
    "feed.title-notifications": "Notifications",
    "feed.title-salesforce": "Customer Feed",
    "filters.Affordable housing": "Affordable housing",
    "filters.Agent Initiated": "Agent Initiated",
    "filters.Customer Initiated: Request": "Customer Initiated: Request",
    "filters.Doors": "Doors",
    "filters.Hotel": "Hotel",
    "filters.In Process": "In Process",
    "filters.Industrial": "Industrial",
    "filters.Invitation to Tender": "Invitation to Tender",
    "filters.KONE Initiated: Prospecting": "KONE Initiated: Prospecting",
    "filters.KONE Initiated: Technician": "KONE Initiated: Technician",
    "filters.Leisure and Education": "Leisure and Education",
    "filters.Maintained by Competitors": "Maintained by Competitors",
    "filters.Maintained by KONE": "Maintained by KONE",
    "filters.Medical": "Medical",
    "filters.Multiuse Segment": "Multiuse Segment",
    "filters.New": "New",
    "filters.Office": "Office",
    "filters.Prospecting/Design Assist": "Prospecting/Design Assist",
    "filters.Public Transportation": "Public Transportation",
    "filters.Qualified": "Qualified",
    "filters.Repair/Modernization": "Repair/Modernization",
    "filters.Residential": "Residential",
    "filters.Retail": "Retail",
    "filters.RFQ": "RFQ",
    "filters.Tender/Proposal": "Tender/Proposal",
    "filters.VA Repairs": "VA Repairs",
    "generic.answer.no": "No",
    "generic.answer.yes": "Yes",
    "generic.button.cancel": "Cancel",
    "generic.button.choose": "Choose",
    "generic.button.continue": "Continue",
    "generic.button.create-close-task": "Create & close task",
    "generic.button.create-event": "Create event",
    "generic.button.delete": "Delete",
    "generic.button.edit-in-salesforce": "Edit in Salesforce",
    "generic.button.edit-in-smt": "Edit in SMT",
    "generic.button.edit-salesforce": "Edit in SalesForce",
    "generic.button.just-close-task": "Just close task",
    "generic.button.kff.audit": "Start Audit",
    "generic.button.konect": "Open KKM",
    "generic.button.kone-online": "View in KOL",
    "generic.button.load-picture": "Load picture",
    "generic.button.logout": "Logout",
    "generic.button.ok": "Ok",
    "generic.button.salesforce": "View in SalesForce",
    "generic.button.save": "Save",
    "generic.button.sync": "Sync data",
    "generic.button.take-picture": "Take picture",
    "generic.button.trace-db": "View in TraceDB",
    "generic.calendar.last": "Last",
    "generic.calendar.today": "Today",
    "generic.calendar.tomorrow": "Tomorrow",
    "generic.calendar.yesterday": "Yesterday",
    "generic.choose-a-file": "Choose a file",
    "generic.closed": "Closed",
    "generic.coming-soon": "Coming soon",
    "generic.customer-group": "Customer Group",
    "generic.empty-subject": "Empty subject",
    "generic.entity.equipment.back-in-order": "Back in order",
    "generic.entity.equipment.normal-operations": "Normal Operations",
    "generic.entity.equipment.out-of-order": "Out of order",
    "generic.entity-name.account": "Account",
    "generic.entity-name.audit": "Audit",
    "generic.entity-name.audit-monthly": "Discussion",
    "generic.entity-name.callout": "Work order",
    "generic.entity-name.complaint": "Complaint",
    "generic.entity-name.equipment": "Equipment",
    "generic.entity-name.lead": "Lead",
    "generic.entity-name.leads": "Leads",
    "generic.entity-name.operations": "Operations",
    "generic.entity-name.opportunities": "Opportunities",
    "generic.entity-name.opportunity": "Sales Lead",
    "generic.entity-name.query": "Query",
    "generic.entity-name.rejectedworkorder": "Rejected Work Order",
    "generic.entity-name.sales-lead": "Sales Lead",
    "generic.entity-name.service-appointment": "Service Appointment",
    "generic.entity-name.spare-parts": "Spare Parts",
    "generic.entity-name.task": "Task",
    "generic.entity-name.technical-helpdesk-case": "THD case",
    "generic.entity-name.technician": "Technician",
    "generic.entity-name.tender": "Tender",
    "generic.entity-name.tenders": "Tenders",
    "generic.entity-name.transactional-survey-case": "Transactional Survey case",
    "generic.entity-name.workorder": "Work Order",
    "generic.equipment-type": "Equipment Type",
    "generic.field.account-name": "Account name",
    "generic.field.account-number": "Account number",
    "generic.field.address": "Address",
    "generic.field.amount": "Amount",
    "generic.field.assembly-code": "Assembly code",
    "generic.field.business-type": "Business type",
    "generic.field.calculated-response-time": "Calculated response time",
    "generic.field.caller-name": "Caller name",
    "generic.field.caller-phone-number": "Number",
    "generic.field.comment": "Comment",
    "generic.field.comments": "Comments",
    "generic.field.company": "Company",
    "generic.field.complaint-number": "Complaint number",
    "generic.field.complaint-origin": "Complaint origin",
    "generic.field.complaint-reason": "Reason",
    "generic.field.construction-year": "Construction year",
    "generic.field.contact-name": "Contact name",
    "generic.field.contact-phone": "Contact Phone",
    "generic.field.contract-type": "Contract type",
    "generic.field.created": "Created",
    "generic.field.created-by": "Created by",
    "generic.field.created-date": "Created Date",
    "generic.field.customer-account": "Customer account",
    "generic.field.customer-name": "Customer name",
    "generic.field.date": "Date",
    "generic.field.date-created": "Date created",
    "generic.field.description": "Description",
    "generic.field.detailed-reason-for-rejection": "Detailed reason for rejection",
    "generic.field.due-date": "Due date",
    "generic.field.earliest-start-date": "Creation date (Unplanned Orders) or  Earliest Start Date (Planned Orders)",
    "generic.field.email": "Email",
    "generic.field.employee-number": "Employee number",
    "generic.field.end-time": "End time",
    "generic.field.equipment-address": "",
    "generic.field.equipment-address:": "",
    "generic.field.equipment-number": "Equipment number",
    "generic.field.equipment-serial-number": "Manufacturer serial number",
    "generic.field.failure-description": "Failure / Module description",
    "generic.field.failure-type": "Failure type",
    "generic.field.invitees": "Invitees",
    "generic.field.job-description": "Job description",
    "generic.field.last-technician-assigned": "Last technician assigned",
    "generic.field.lead-created-by-and-date": "Created by / date",
    "generic.field.lead-product-service-interest": "Product/Service interest",
    "generic.field.lead-source": "Lead source",
    "generic.field.location": "Location",
    "generic.field.manufacturer": "Manufacturer",
    "generic.field.mobile-phone": "Mobile phone",
    "generic.field.npi-score": "NPI score",
    "generic.field.objectives": "Objectives",
    "generic.field.opportunity-name": "Opportunity name",
    "generic.field.opportunity-number": "Opportunity number",
    "generic.field.owner": "Owner",
    "generic.field.phone": "Phone",
    "generic.field.primary-contact": "Primary contact",
    "generic.field.priority": "Priority",
    "generic.field.quantity": "Quantity",
    "generic.field.query-category": "Query category",
    "generic.field.query-number": "Query number",
    "generic.field.query-parent": "Parent query / complaint",
    "generic.field.query-reason": "Reason",
    "generic.field.related-to": "Related to",
    "generic.field.response-received": "Response received",
    "generic.field.scheduled-end-date": "Scheduled End Date",
    "generic.field.scheduled-start-date": "Scheduled Start Date",
    "generic.field.stage": "Stage",
    "generic.field.start-date": "Start date",
    "generic.field.start-time": "Start time",
    "generic.field.status": "Status",
    "generic.field.subject": "Subject",
    "generic.field.supervisor-comment": "Special Assignment comment",
    "generic.field.supervisor-will-manage": "Supervisor will manage",
    "generic.field.supervisor-will-manage-reason": "Supervisor will manage reason",
    "generic.field.survey-name": "Survey name",
    "generic.field.technician-name": "Technician name",
    "generic.field.template": "Template",
    "generic.field.tender-numbers": "Tender number",
    "generic.field.title": "Title",
    "generic.field.total-sales-price": "Total sales price",
    "generic.field.type": "Type",
    "generic.field.type-code": "Type code",
    "generic.field.visit-type-priority": "Visit type",
    "generic.filter.all": "Filter All",
    "generic.filter-by": "Filter by",
    "generic.group-by": "Group by",
    "generic.market-segment": "Market Segment",
    "generic.open": "Open",
    "generic.placeholder.account-choose": "Choose account",
    "generic.placeholder.contact-account-first": "Choose an account first",
    "generic.placeholder.contact-customer": "Choose customer contact",
    "generic.placeholder.description": "Description",
    "generic.placeholder.email-address": "Please fill in email address",
    "generic.placeholder.event-type-choose": "Choose activity type",
    "generic.placeholder.fax": "Fax number",
    "generic.placeholder.firstname": "Please fill in first name",
    "generic.placeholder.function-choose": "Function",
    "generic.placeholder.lastname": "Please fill in last name",
    "generic.placeholder.location": "Add location",
    "generic.placeholder.mobile-number": "Mobile number",
    "generic.placeholder.mobile-phone": "Please fill in mobile number",
    "generic.placeholder.no-function-assigned": "There is no function assigned",
    "generic.placeholder.no-title-assigned": "There is no title assigned",
    "generic.placeholder.objectives-choose": "Choose objectives",
    "generic.placeholder.participants-choose": "Type a name or choose participants from list below",
    "generic.placeholder.participants-filter": "Filter participants",
    "generic.placeholder.phone-number": "Please fill in phone number",
    "generic.placeholder.title": "Title",
    "generic.placeholder.type-choose": "Type",
    "generic.placeholder.write-something": "Write something...",
    "generic.priority.high": "High",
    "generic.priority.normal": "Normal",
    "generic.validation.enum-value-message": "Enum Value Message",
    "layout.empty-view.description": "Description",
    "layout.empty-view.title": "No results found",
    "layout.error-view.description": "Error occurred while loading this view please refresh.\\nIf the problem persists please contact your local administrator.",
    "layout.error-view.title": "Error",
    "layout.report-item.button.view-report": "View report",
    "layout.report-item.empty-subtitle": "Use the icon in the top bar to generate one",
    "layout.report-item.empty-title": "No reports generated for this account",
    "layout.report-item.status.error": "Error",
    "layout.report-item.status.in-progress": "Generating...",
    "lead.field.business-type": "Business type",
    "lead.field.lead-source": "Lead source",
    "lead.field.stage-name": "Lead Status",
    "lead.field.urgency": "Urgency",
    "link.link-1.title": "Test LINK Title",
    "link.link-2.description": "Test LINK Google Web",
    "links-page.title": "Useful links",
    "list-detail.section-title.base-info": "Basic info",
    "list-detail.section-title.customer-report": "Customer report",
    "locate-page.tab.equipment": "Equipment",
    "locate-page.tab.open-so": "Open WO",
    "locate-page.tab.opportunities": "Open opportunity",
    "locate-page.tab.technicians": "Technicians",
    "locate-page.title": "Locate",
    "login-page.app-title": "Supervisor Mobility Tools",
    "login-page.description.line-1": "Welcome to Supervisor Mobility Tools.",
    "login-page.description.line-2": "In order to log in please use button below.",
    "login-page.description.line-3": "You need to use your Salesforce credentials.",
    "login-page.main-action-button": "Log in with Salesforce",
    "map.equipments-layers-modal.active": "Active",
    "map.equipments-layers-modal.contract-status-subtitle": "Show on map",
    "map.equipments-layers-modal.expired": "Expired",
    "map.equipments-layers-modal.future": "Future",
    "map.equipments-layers-modal.title": "Show on map",
    "map.equipments-layers-modal.work-center-choice": "If nothing is selected, equipments for all workcenters will be visible",
    "map.equipments-layers-modal.work-center-subtitle": "Work center",
    "map.service-order-type.high-priority": "High Priority",
    "map.service-order-type.other": "Other",
    "map.service-order-type.unplanned": "Unplanned",
    "map-equipment-filtering-modal.contract-status": "Contract Status",
    "map-equipment-filtering-modal.equipment-status": "Equipment status",
    "map-equipment-filtering-modal.work-center": "Work Center",
    "map-opportunity-filtering-modal.opportunity-business-types": "Business types",
    "map-service-order-layers-modal.cancel": "Cancel",
    "map-service-order-layers-modal.choose": "Choose",
    "map-service-order-layers-modal.description": "If nothing is selected all Job types will be visible",
    "map-service-order-layers-modal.title": "Show on map",
    "modal.account.not-available": "Account information is not available.",
    "modal.audit-event.title": "Audit event",
    "modal.contact.group-title": "Contacts",
    "modal.contact-note.create-note-title": "Note from KONE SMT",
    "modal.contact-note-title": "Add Note",
    "modal.contact-photo.add-attachment": "You can take a new picture as attachment or select an existing image file from your device's storage.",
    "modal.contact-photo.title": "Adding an attachment",
    "modal.create-contact.title": "Create new contact",
    "modal.edit-contact.edit-info": "Edit information",
    "modal.edit-contact.no-title": "No title",
    "modal.equipment.tab.accountandcontract": "Account & Contract",
    "modal.equipment.tab.basicinfo": "Basic Info",
    "modal.equipment.tab.crminfo": "CRM Info",
    "modal.equipment.tab.serviceorders": "Work orders",
    "modal.list-entities.title": "Choose an item",
    "modal.permission-denied.body": "Unfortunately you don't have a permission to use Supervisor Mobility Tools. Please contact your IT administrator.",
    "modal.permission-denied.title": "Access denied",
    "modal.question.create-event-body": "Do you want to create an event in Salesforce for this task and provide notes from the meeting with client? After this the task and event will be automatically marked as completed",
    "modal.question.create-event-title": "Create event?",
    "modal.question.enter-offline.content": "Your device seems to be offline and has no data connection. You can continue to view the data visible on screen by pressing ignore?or you can switch to offline mode?",
    "modal.question.enter-offline.ignore": "Ignore",
    "modal.question.enter-offline.switch": "Offline mode",
    "modal.question.enter-offline.title": "No data connection",
    "modal.question.leave-offline.content": "Before switching to online mode the data saved during offline mode will be synchronised.",
    "modal.question.leave-offline.title": "Switch to Online mode",
    "modal.select-contacts.billed-to": "Billed to",
    "modal.select-contacts.decided-by": "Decided by",
    "modal.select-contacts.no-contacts": "No contacts for account",
    "modal.select-contacts.related-accounts": "Related Accounts",
    "modal.select-contacts.sold-to": "Sold to",
    "modal.select-contacts.title": "Add participants",
    "modal.technician.completed-jobs": "Work orders completed",
    "modal.technician.planned-jobs": "Planned jobs left",
    "modal.technician.serviceorders.completed-today": "Work orders completed today",
    "modal.technician.serviceorders.mbm": "Open MBMs in Workcenter",
    "modal.technician.serviceorders.open-others": "Other Open Work Orders Assigned",
    "modal.technician.serviceorders.unplanned": "Open callouts assigned",
    "modal.technician.tab.basicinfo": "Basic Info",
    "modal.technician.tab.serviceorders": "Work orders",
    "modal.technician.unplanned-jobs": "Unplanned jobs left",
    "modal.write-event.title": "Customer visit",
    "modals.confirm-logout.cancel": "CANCEL",
    "modals.confirm-logout.confirm": "LOG OUT",
    "modals.confirm-logout.content": "Are you sure you would like to log out of Kone SMT?",
    "modals.confirm-logout.title": "Log out",
    "modals.select-planner-group.content": "Please select the Service Territory to which you want to log into. Please contact your administrator in case you don't see some of the Service Territories",
    "modals.select-planner-group.search-input-placeholder": "Search Planner Group",
    "modals.select-planner-group.title": "Select Service Territory",
    "notes-modal.title": "Note",
    "notifications.callout.entrapment": "Emergency callout %s - %s",
    "notifications.callout.highPriority": "High priority callout %s - %s",
    "notifications.callout.newSvWillManage": "Supervisor to manage callout %s - %s",
    "notifications.callout.rejected": "Rejected callout %s - %s",
    "notifications.callout.technicalHelpDesk": "Technical help desk case %s - %s",
    "notifications.dataChanged": "Data changed",
    "notifications.equipment.assigned": "A new equipment has been assigned to your Service Territory - %s",
    "notifications.equipment.assigned.new": "New equipment <value>equipment number</value> assigned to your planner group",
    "notifications.equipment.lost": "Maintenance contract ended for equipment <value>equipment number</value> in your planner group",
    "notifications.equipment.stopped": "Equipment %s is stopped",
    "notifications.equipment.transfered": "Equipment <value>equipment number</value> moved to another planner group",
    "notifications.report.generated": "Report for %s was generated",
    "notifications.salesForce.assignedComplaintUpdated": "Complaint assigned to you has been updated",
    "notifications.salesForce.taskForComplaintUpdated": "Task related to a complaint has been updated",
    "notifications.sickLift.alert": "Sick lift alert %s %s.",
    "notifications.technicianAlarm.panic": "Panic alarm has been received from %s",
    "notifications.technicianAlarm.safety": "Safety warning has been received from %s",
    "offline-accounts-page.search-placeholder": "Type at least 3 characters to search accounts?",
    "offline-accounts-page.title": "Accounts",
    "offline-status": "Status",
    "opportunity.field.amount": "Amount",
    "opportunity.field.close-date": "Close date",
    "opportunity.field.created-date": "Created date",
    "opportunity.field.lead-source": "Lead source",
    "opportunity.field.market-segment": "Market segment",
    "opportunity.field.opportunity-category": "Opportunity category",
    "opportunity.field.owner": "Owner",
    "opportunity.field.stage-name": "Stage",
    "opportunity.field.technician-name": "Technician name",
    "opportunity.filter.all": "All",
    "opportunity.filter.planner-group": "Assigned to the Service Territory",
    "opportunity.filter.supervisor": "Assigned to the Supervisor",
    "opportunity.filter.va-repairs-and-doors": "VA Repairs & Doors",
    "opportunity.filter.va-repairs-or-doors": "VA Repairs/Doors",
    "opportunity-business-type.doors": "Doors",
    "opportunity-business-type.ebuli": "Ebuli",
    "opportunity-business-type.maintenance-seb": "Maintenance (SEB)",
    "opportunity-business-type.modernization-frb": "Modernization (FRB)",
    "opportunity-business-type.modernization-neb": "Modernization (TRP)",
    "opportunity-business-type.new-equipment-neb": "New Equipment (NEB)",
    "opportunity-business-type.spares": "Spares",
    "opportunity-business-type.va-repairs": "VA Repairs",
    "opportunity-business-type.vb-repairs": "VB Repairs",
    "opportunity-details.tab.basicinfo": "Basic info",
    "opportunity-details.tab.relatedcontacts": "Related Contacts",
    "overview-page.customer-overview": "Customers overview",
    "overview-page.customer-overview.customer-visits": "Customer visits",
    "overview-page.customer-overview.detractor-cases": "Open Detractor Cases",
    "overview-page.customer-overview.open-complaints": "Open Complaints",
    "overview-page.customer-overview.open-leads": "Open Sales Leads",
    "overview-page.customer-overview.open-opportunities": "Sales Leads without Tender",
    "overview-page.customer-overview.open-opportunities-customers": "Sales Leads without Tender",
    "overview-page.customer-overview.open-opportunities-owned": "Sales Leads without Tender",
    "overview-page.customer-overview.open-planner-group-tenders": "Open Service Territory tenders",
    "overview-page.customer-overview.open-queries": "Open Queries",
    "overview-page.customer-overview.open-supervisor-tenders": "Open supervisor tenders",
    "overview-page.customer-overview.open-tasks": "Open Tasks",
    "overview-page.customer-overview.opportunities-with-tenders": "Salesleads with Tenders",
    "overview-page.customer-overview.opportunities-with-tenders-customers": "Salesleads with Tenders",
    "overview-page.customer-overview.opportunities-with-tenders-owned": "Salesleads with Tenders",
    "overview-page.customer-overview.sales-leads": "Sales Leads without Tender",
    "overview-page.customer-overview.sales-leads-customers": "Sales Leads without Tender",
    "overview-page.customer-overview.sales-leads-owned": "Sales leads without Tenders",
    "Overview-page.customer-overview.technical-helpdesk-cases": "Technical Helpdesk cases",
    "overview-page.my-customers-open-activities": "My customers open activities",
    "overview-page.my-open-activities": "My open activities",
    "overview-page.my-technicians-open-activities": "My technicians open activities",
    "overview-page.operations- overview.open-repairs": "Open Repairs",
    "overview-page.operations-overview": "Operations overview",
    "overview-page.operations-overview.field-audits": "Field audits",
    "overview-page.operations-overview.inspection-points": "Inspection points",
    "overview-page.operations-overview.inspections": "Open Inspections",
    "overview-page.operations-overview.mbm-left-this-month": "Open MBMs",
    "overview-page.operations-overview.monthly-discussions": "Monthly discussions",
    "overview-page.operations-overview.open-repairs": "Open Repairs",
    "overview-page.operations-overview.open-timesheets-to-be-approved": "Timesheets to be approved",
    "overview-page.operations-overview.open-unplanned-jobs": "Open callouts",
    "overview-page.operations-overview.rejected-service-appointments": "Rejected service appointments in last 24h",
    "overview-page.operations-overview.rejected-work-orders": "Rejected Work Orders in last 24h",
    "overview-page.operations-overview.service-needs": "Service Needs",
    "overview-page.operations-overview.stopped-equipment": "Stopped equipment",
    "overview-page.or-repairs-funnel": "REPAIRS FUNNEL OVERVIEW",
    "overview-page.tile-info.completed": "Completed this month",
    "overview-page.tile-info.created-this-month": "created this month",
    "overview-page.tile-info.for": "for",
    "overview-page.tile-info.orders-received-this-month": "orders received this month",
    "overview-page.tile-info.orders-recieved-this-month": "orders received this month",
    "overview-page.tile-info.or-repairs-completed": "repairs completed this month",
    "overview-page.tile-info.tenders-created-this-month": "created this month",
    "overview-page.title": "Overview",
    "plan-page.title": "Plan",
    "product-consumed.field.spare-part-number": "Spare part number",
    "repair.filter.all": "All",
    "repair.filter.clinica-visits": "Clinica Visit",
    "repair.filter.express-letters": "Express letters",
    "repair.filter.field-letters": "Field letters",
    "repair.filter.first-visit-defects": "First Visit Defects",
    "repair.filter.other": "Other",
    "repair.filter.packaged-service-repair": "Packaged Service Repair",
    "repair.filter.planned-service-repairs": "Planned Service Repair",
    "repair.filter.unplanned-service-repair": "Unplanned Service Repair",
    "repair.grouping.none": "All",
    "repair.grouping.type": "Type",
    "search-account-modal.input-placeholder": "Type Account",
    "search-account-modal.title": "Search Account",
    "search-account-or-equipment-modal.input-placeholder": "Search Account or Equipment",
    "search-page.block-label.default": "Type any name or number in the search field above",
    "search-page.block-label.hint": "Please check spelling or try different keywords",
    "search-page.block-title.empty-results": "No results found",
    "search-page.block-title.exact-search": "Exact search",
    "search-page.block-title-default": "Search Kone SMT",
    "search-page.group-title.accounts": "Accounts",
    "search-page.group-title.all": "All",
    "search-page.group-title.audits": "Audits",
    "search-page.group-title.callouts": "Work orders",
    "search-page.group-title.complaints": "Complaints",
    "search-page.group-title.equipments": "Equipments",
    "search-page.group-title.events": "Events",
    "search-page.group-title.leads": "Leads",
    "search-page.group-title.opportunities": "Sales Leads",
    "search-page.group-title.queries": "Queries",
    "search-page.group-title.sales-leads": "Sales Leads",
    "search-page.group-title.tasks": "Tasks",
    "search-page.group-title.technicians": "Technicians",
    "search-page.group-title.tenders": "Tenders",
    "search-page.group-title.timesheets": "Timesheets",
    "search-page.group-title.users": "Users",
    "search-page.title": "Search",
    "search-page-search-input-placeholder": "Type in at least 3 characters to search",
    "search-user-modal.input-placeholder": "Type to search users?",
    "search-user-modal.title": "Select user",
    "service.appointment.field.technician": "Technician",
    "service.appointment.field.time-of-rejection": "Time of rejection",
    "service-appointment.field.rejection-date": "Rejection date",
    "service-appointment.field.technician": "Technician",
    "service-appointment.field.time-of-rejection": "Time of rejection",
    "service-needs.filter.all": "All service needs",
    "service-needs.filter.missed-service-needs": "Missed Standard Service Needs",
    "service-order-failure-type-code.KM-AA": "Emergency (KM-AA)",
    "service-order-failure-type-code.KM-AB": "High Priority (KM-AB)",
    "service-order-failure-type-code.KM-AC": "Low Priority (KM-AC)",
    "service-order-failure-type-code.KM-AD": "KXN test alarm (KM-AD)",
    "service-order-failure-type-code.KM-AE": "Elevator Failure (KM-AE)",
    "service-order-failure-type-code.KM-AF": "Service Failure (KM-AF)",
    "service-order-failure-type-code.KM-AG": "Service Need (KM-AG)",
    "service-order-failure-type-code.KM-AH": "Missing routine call (KM-AH)",
    "service-order-failure-type-code.KM-AI": "Port comm. lost (KM-AI)",
    "service-order-failure-type-code.KM-AJ": "Front end failure (KM-AJ)",
    "service-order-failure-type-code.KM-AK": "Perform during service visit (KM-AK)",
    "service-order-failure-type-code.KM-AL": "System Failure (KM-AL)",
    "service-order-konect-status.0": "New",
    "service-order-konect-status.1": "On Hold",
    "service-order-konect-status.10": "WIP",
    "service-order-konect-status.11": "Interrupted",
    "service-order-konect-status.12": "Continued",
    "service-order-konect-status.13": "Finished",
    "service-order-konect-status.14": "Closed",
    "service-order-konect-status.15": "Voice Mail",
    "service-order-konect-status.16": "Cancelled",
    "service-order-konect-status.17": "Initiated",
    "service-order-konect-status.18": "Accepted",
    "service-order-konect-status.19": "Rejected",
    "service-order-konect-status.2": "Unreachable",
    "service-order-konect-status.20": "Delivered",
    "service-order-konect-status.21": "Gateway reset",
    "service-order-konect-status.22": "Sent to KFM",
    "service-order-konect-status.23": "Confirm",
    "service-order-konect-status.24": "Leave Site",
    "service-order-konect-status.25": "Forward",
    "service-order-konect-status.3": "Dispatched",
    "service-order-konect-status.4": "Returned",
    "service-order-konect-status.5": "Received",
    "service-order-konect-status.6": "On Route",
    "service-order-konect-status.7": "Arrived",
    "service-order-konect-status.8": "Started",
    "service-order-konect-status.9": "Reassigned",
    "service-orders-report.group-by.Type": "Group by Type",
    "service-orders-report.group-by.Workcenter": "Group by Work Center",
    "service-orders-report.service-order-types.placeholder": "WO Type",
    "service-orders-report.title": "Open Work Orders Report",
    "service-orders-report.total": "Total",
    "service-orders-report.type": "Type",
    "service-orders-report.workcenters": "Work Centers",
    "service-orders-report.workcenters.placeholder": "Work Center",
    "service-orders-report-page.title": "Open Work Orders Report",
    "service-orders-summary.all-service-types": "All Service Types",
    "service-orders-summary.all-work-centers": "All Work Centers",
    "service-orders-summary.filter.by-type.sub-title": "By type",
    "service-orders-summary.filter.by-type.title": "By type",
    "service-orders-summary.filter.by-work-centers.sub-title": "By Work Center",
    "service-orders-summary.filter.by-work-centers.title": "By Work Center",
    "service-orders-summary.service-order-type": "Service Order Type",
    "service-orders-summary.tab.by-type": "By Type",
    "service-orders-summary.tab.by-work-center": "By Work Center",
    "service-orders-summary.title": "Summary",
    "service-orders-summary.workcenter": "Work Center",
    "service-orders-summary.work-center": "Work Center",
    "service-order-type.all-but-y03": "All types except Y03 - Maintenance",
    "serviceorder-type.high-priority": "Emergency/High priority",
    "serviceorder-type.other": "Other",
    "serviceorder-type.unplanned": "Unplanned",
    "service-order-type.y01": "Customer Call",
    "service-order-type.y02": "Konexion Call",
    "service-order-type.y03": "Maintenance",
    "service-order-type.y04": "Repair",
    "service-order-type.y04_ysm1_ysm2": "Planned Service Repair (Non-Billable)",
    "service-order-type.y04_ysm3_ysm4": "Unplanned Service Repair",
    "service-order-type.y04_ysm6": "Tendered Repair",
    "service-order-type.y05": "SEB Warranty",
    "service-order-type.y06": "Inspection",
    "service-order-type.y07": "Inspection Points",
    "service-order-type.y08": "Site Survey",
    "service-order-type.y09": "Service needs",
    "service-order-type.y16": "Janitor Service",
    "service-order-type.z01": "Call out (Billable)",
    "service-order-type.z02": "Callout from Remote Monitoring device (Billable)",
    "service-order-type.z03": "Planned Maintenance Billable",
    "service-order-type.z04": "Repair",
    "service-order-type.z06": "Inspection Visit Billable",
    "service-order-type.z07": "Inspection Points Billable",
    "synchronisation.back-to-online-toast.button": "Switch to online",
    "synchronisation.back-to-online-toast.caption": "Internet connection available",
    "synchronisation.connot-go-online-toast": "Internet connection is still not available.",
    "synchronisation.summary-modal.completed-event": "Status update of",
    "synchronisation.summary-modal.edit-contact": "Info update of",
    "synchronisation.summary-modal.new-attachment": "New attachment for",
    "synchronisation.summary-modal.new-event": "New event",
    "synchronisation.summary-modal.new-note": "New note",
    "synchronisation.summary-modal.title": "Data synchronisation summary",
    "task-priority.high": "High",
    "task-priority.low": "Low",
    "task-priority.normal": "Normal",
    "tasks.declinedsa.rejectionDate": "Time of rejection",
    "tasks.declinedsa.rejectionReasons": "Reason of rejection",
    "tasks.declinedsa.serviceAppointmentNumber": "Service Appointment",
    "tasks.declinedsa.title": "@COUNT@ appointments have been declined in @DAYS@ days by technician @NAME@",
    "tasks.declinedsa.titleKonect": "@COUNT@ orders have been declined in @DAYS@ days by technician @NAME@",
    "tasks.declinedsa.workOrderNumber": "Work Order",
    "tasks.equipment.inform.supervisor.assigned.new": "New equipment assigned to your planner group",
    "tasks.equipment.inform.supervisor.assigned.new.description": "New equipment <value>equipment number</value> assigned to your planner group",
    "tasks.equipment.inform.supervisor.lost": "Maintenance contract has ended for an equipment <value>equipment number</value> in your planner group",
    "tasks.equipment.inform.supervisor.lost.description": "Maintenance contract <value>maintenance contract number</value> for equipment <value>equipment number</value> has ended",
    "tasks.equipment.inform.supervisor.transfered": "Equipment <value>equipment number</value> moved to another planner group",
    "tasks.equipment.inform.supervisor.transfered.description": "Equipment <value>equipment number</value> in your planner group has been moved to planner group <value>planner group name</value>",
    "tasks.onboarding.contractNumber": "Contract number",
    "tasks.onboarding.contractStartDate": "Contract start date",
    "tasks.onboarding.customerContact": "Customer contact",
    "tasks.onboarding.desc": "Please arrange an on-boarding visit with our new customer as outlined below. As we know we have an opportunity to exceed our Customer's expectations by performing an on-boarding visit within the first 7 days of this contract start date. Please notify your Technician responsible for this site and arrange that they attend with you to make the experience satisfying for all concerned.",
    "tasks.onboarding.equipmentNumber": "Equipment number",
    "tasks.onboarding.equipmentsInContract": "Equipments in Contract",
    "tasks.onboarding.equipmentType": "Equipment type",
    "tasks.onboarding.equipment-type": "Equipment type",
    "tasks.onboarding.headOfTenants": "Head of tenants",
    "tasks.onboarding.mobile": "Mobile",
    "tasks.onboarding.na": "n/a",
    "tasks.onboarding.phone": "Phone",
    "tasks.onboarding.siteAddress": "Site address",
    "tasks.onboarding.siteName": "Site name",
    "tasks.onboarding.technicianAssigned": "Technician assigned",
    "tasks.onboarding.title": "Schedule on-boarding visit",
    "tasks.panicAlarm.alertChangedDate": "Alert change date",
    "tasks.panicAlarm.alertStatus": "Alert status",
    "tasks.panicAlarm.basicDesc": "A panic alert has been received from technician %s at %s. Please follow up.",
    "tasks.panicAlarm.lastLocation": "Last location",
    "tasks.panicAlarm.lastServiceOrder": "Last Work Order",
    "tasks.panicAlarm.na": "n/a",
    "tasks.panicAlarm.phone": "Technician phone number",
    "tasks.panicAlarm.subject": "Panic Alarm - %s",
    "tasks.reminder.customerName": "Customer name",
    "tasks.reminder.failureType": "Failure type",
    "tasks.reminder.forCallout": "Call for callout",
    "tasks.reminder.forEquipment": "for equipment",
    "tasks.reminder.jobDescription": "Job description",
    "tasks.reminder.na": "n/a",
    "tasks.reminder.title": "Schedule customer call",
    "tasks.reminder.title.entrapment": "Call client after emergency - %s",
    "tasks.reminder.title.highPriority": "Call client after high priority callout - %s",
    "tasks.reminder.visitTypeCode": "Visit type code",
    "tasks.safetyAlarm.basicDesc": "A safety alert has been received from technician %s at %s. This is for your information.",
    "tasks.safetyAlarm.na": "n/a",
    "tasks.safetyAlarm.subject": "Inactive technician - %s",
    "tasks.sickLift.actionDesc": "Action Description",
    "tasks.sickLift.arrivedTime": "Arrived Time",
    "tasks.sickLift.atSite": "at site",
    "tasks.sickLift.componentDesc": "Component description",
    "tasks.sickLift.condition": "Condition on Arrival",
    "tasks.sickLift.contactDetails": "Primary Customer contact details",
    "tasks.sickLift.dispatchTime": "Dispatched Time",
    "tasks.sickLift.eqNumber": "Equipment number",
    "tasks.sickLift.eqStatus": "Equipment status",
    "tasks.sickLift.failureDesc": "Failure description",
    "tasks.sickLift.failuresInvestigated": "failures which must be investigated",
    "tasks.sickLift.finishedDate": "Finished date",
    "tasks.sickLift.followingEq": "The following equipment",
    "tasks.sickLift.hasHad": "has had",
    "tasks.sickLift.jobDesc": "Job Description",
    "tasks.sickLift.na": "n/a",
    "tasks.sickLift.reasonDesc": "Reason Description",
    "tasks.sickLift.serviceOrder": "Work order",
    "tasks.sickLift.serviceOrders": "Work orders",
    "tasks.sickLift.siteAddress": "Site address",
    "tasks.sickLift.siteName": "Site name",
    "tasks.sickLift.title": "Sick lift",
    "task-status.cancelled": "Cancelled",
    "task-status.closed": "Completed",
    "task-status.open": "Not Started",
    "technical-helpdesk-case.number": "THD case number",
    "technical-helpdesk-case.sla-status": "SLA status",
    "technician.field.date": "Date",
    "technician.field.email": "Technician email",
    "technician.field.fitter-number": "Employee number",
    "technician.field.last-known-location": "Last known location",
    "technician.field.last-service-order": "Current work order",
    "technician.field.name": "Technician",
    "technician.field.next-planned-absence": "Next planned absence",
    "technician.field.open-audits": "Open audits",
    "technician.field.open-discussions": "Open monthly discussions",
    "technician.field.phone": "Phone",
    "technician.field.status": "Status",
    "technician.field.work-center-number": "Work center number",
    "technician.field.workorder-location": "Technician WorkOrder Location",
    "technician-activity-status.absent": "Absent",
    "technician-activity-status.callout": "Callout",
    "technician-activity-status.emergencycallout": "Status Emergency callout",
    "technician-activity-status.emergency-callout": "Emergency callout",
    "technician-activity-status.inactive": "Inactive",
    "technician-activity-status.working": "Working",
    "technician-report.filter.distant-technicians": "Distant Technicians",
    "technician-report.table.difference": "Difference",
    "technician-report.table.email": "Email",
    "technician-report.table.employeeNumber": "Employee",
    "technician-report.table.equipmentPosition": "Equipment Position",
    "technician-report.table.firstName": "Technician First Name",
    "technician-report.table.lastName": "Technician Last name",
    "technician-report.table.name": "Name",
    "technician-report.table.phone": "Phone",
    "technician-report.table.serviceAppointmentPosition": "Service Appointment position",
    "technician-report.table.serviceOrder": "Work Order",
    "technician-report.table.status": "Status",
    "technician-report.table.technicianPosition": "Technician Position",
    "technician-report.table.workcenterNumber": "Workcenter",
    "technician-report.title": "Technician Report",
    "toast.attachment-create-failed.message": "Error occurred during saving attachment. Please add it once again.",
    "toast.attachment-create-successful.message": "Attachment created successfully.",
    "toast.auth-failed.message": "Authorization failed.",
    "toast.calendar-create-failed.message": "There is a problem with adding event to the calendar.",
    "toast.calendar-remove-failed.message": "There is a problem with removing event from the calendar.",
    "toast.calendar-sync-failed.message": "Native calendar wasn't synchronized.",
    "toast.close-task.failure.message": "Failed to close task.",
    "toast.close-task.success.message": "Task has been closed.",
    "toast.complete-service-order.failure.message": "Failed to mark work order as completed.",
    "toast.complete-service-order.success.message": "Work order task has been marked as completed.",
    "toast.contact-update-failed.message": "Contact update failed",
    "toast.contact-update-failed.message-duplicates-detected": "Duplicate found. Please select existing one or create new in Salesforce",
    "toast.contact-update-successful.message": "Contact updated successfully, please refresh the page",
    "toast.create-task.failure.message": "Failed to create the task.",
    "toast.create-task.success.message": "Task has been created.",
    "toast.equipment-failed.message": "Couldn't load equipment.",
    "toast.event-created-failed.message": "Event wasn't created in Salesforce",
    "toast.event-created-successful.message": "Event has been created",
    "toast.failed-to-load-details.message": "Failed to load item's details.",
    "toast.geo-filter-failed.message": "There is a problem with filtering activity feed based on your map position.",
    "toast.geo-location-failed.message": "Can't load current position.",
    "toast.insufficient-access": "Insufficient rights to edit this content",
    "toast.invalid-company-settings.message": "Invalid company settings",
    "toast.load-picture-failed.message": "Can't load picture on your device.",
    "toast.mark-event-completed-failed.message": "Cannot mark event as completed.",
    "toast.meeting-created.message": "Meeting has been created.",
    "toast.meeting-failed.message": "Meeting wasn't created in Salesforce.",
    "toast.meeting-resolved.message": "Meeting has been resolved.",
    "toast.note-create-failed.message": "Error occurred during saving note.",
    "toast.note-create-successful.message": "Note created successfully.",
    "toast.obsolete-map-data.action": "Refresh map",
    "toast.obsolete-map-data.message": "Your map data is not up-to-date.",
    "toast.open-external-app-failed.message": "There is a problem with opening this application. Did you install it on the device?",
    "toast.register-device-failed.message": "There will be a problem with push notifications. Failed to register the device.",
    "toast.save-notes.failure.message": "Failed",
    "toast.save-notes.success.message": "Saved",
    "toast.sf-account-failed.message": "Couldn't load Salesforce account.",
    "toast.sf-accounts-contacts-failed.message": "Couldn't load Salesforce account contacts.",
    "toast.sf-accounts-failed.message": "Couldn't load Salesforce accounts.",
    "toast.sf-contacts-failed.message": "Couldn't load Salesforce contacts.",
    "toast.sf-job-functions-failed.message": "Couldn't load Salesforce job functions.",
    "toast.take-picture-failed.message": "Can't take picture on your device.",
    "toast.tracking-report-not-found.message": "Traction control report not found",
    "tracking-control.download-report": "Download TC Report",
    "tracking-control.monthly-reports": "TC Monthly Reports",
    "tracking-control.planner-group": "Planner Group",
    "tracking-control.planner-group-reports": "Planner Group level reports",
    "tracking-control.reports.label": "Traction Control Report",
    "tracking-control.title": "Traction control",
    "tracking-control.va.label": "Traction Control - VA Report",
    "tracking-control.va.name": "VA",
    "tracking-control.vc.label": "Traction Control - VC Report",
    "tracking-control.vc.name": "VC",
    "tracking-control.weekly-reports": "TC Weekly Report",
    "tracking-control.work-center": "Work Center",
    "tracking-control.workcenter-reports": "Work center level reports",
    "traction-control-reports.item.planner-group": "Planner Group",
    "traction-control-reports.item.work-center": "Work Center",
    "traction-control-reports.monthly": "Monthly",
    "traction-control-reports.no-monthly-reports": "Monthly Reports",
    "traction-control-reports.no-work-center-reports": "No Work Center reports",
    "traction-control-reports.planner-group": "Planner Group",
    "traction-control-reports.section.monthly": "Monthly",
    "traction-control-reports.section.weekls": "Weekls",
    "traction-control-reports.section.weekly": "Weekly",
    "traction-control-reports.weekly": "Weekly",
    "traction-control-reports.work-center": "Work Center",
    "units.km": "Km",
    "units.mile": "Miles",
    "validation-messages-dialog.description": "Description",
    "validation-messages-dialog.title": "Title",
    "workorder.tab.basicinfo": "Basic info",
    "workorder.tab.completedmbms": "Completed MBMs after Service need creation",
    "workorder.tab.moduledetails": "Module details",
    "write-task-modal.title": "New task"
  };
export default translations;
