import * as React from 'react';
import { connect } from 'react-redux';
import { IGlobalState, localeFromState, activeInFSMFromState } from '../../models/globalState';
import * as _ from 'lodash';
import { IStoppedEquipmentReport } from '../../reducers/stoppedEquipmentReportReducer';
import * as moment from 'moment';
import { queryStoppedEquipmentReport } from '../../actions/integration/equipmentActions';
import * as cx from 'classnames';
import { formatDateMonthYear } from '../../utils/formatUtils';
import PanelPage from '../../containers/pages/PanelPage';
import { CoveringSpinner } from '../CoveringSpinner';
import { createSelector } from 'reselect';
import { Table } from '../table/Table';
import { isAndroid } from 'src/utils/cordova-utils';
import { openListOfEntitiesOrOpenItem, queryAndShowDetailsDialogGeneric } from '../../actions/integration/detailsActions';
import { closeMenu } from 'src/containers/AppBase';
import { queryEquipmentsByIds } from '../../actions/http/jsforce';
import { entityFieldsDataFromState } from '../fields/EntityFieldsData';
import { showModal } from '../../actions/modalActions';
import { ModalType } from '../../constants/modalTypes';
import Equipment from 'src/models/equipment/Equipment';

interface IStoppedEquipmentsReportDispatchProps {
    queryStoppedEquipmentReport: typeof queryStoppedEquipmentReport;
    queryAndShowDetailsDialogGeneric: typeof queryAndShowDetailsDialogGeneric;
    closeMenu: typeof closeMenu;
}

interface IStoppedEquipmentsReportProps {
    months: string[];
    histogram: string[][];
    maxValue: number;
    keys: string[];
    locale: string;
}

class StoppedEquipmentsClass extends React.Component<IStoppedEquipmentReport & IStoppedEquipmentsReportProps & IStoppedEquipmentsReportDispatchProps, object> {

    public render() {
        const { actionStatus, months, histogram, maxValue } = this.props;
        const currentMonth = formatMonth(moment(), this.props.locale);

        return (
            <PanelPage>
                <div className='margin-top--large flex--1 relative'>
                    <div className='cover-all scrollable'>
                        <CoveringSpinner isSmall actionStatus={actionStatus} />
                        <Table
                            colgroup={
                                <colgroup>
                                    <col />
                                    {months.map((m, index) => (
                                        <col
                                            key={index}
                                            className={cx({
                                                'data-table-highlighted': currentMonth === m,
                                                'data-table-border-right': index === 0 || index === 10
                                            })}
                                        />
                                    ))}
                                    <col />
                                </colgroup>
                            }
                            thPrefix="stopped-equipments-report"
                            ths={[
                                { label: 'Month', dontTranslate: true },
                                ...months.map(m => ({ label: m, dontTranslate: true })),
                                { label: 'Total' },
                            ]}
                            rows={[histogram.map(row => row.length)]} 
                            row={(rowData: number[], rowIndex: number) => [
                                <td key={`label-${rowIndex}`}>Stopped Equipments</td>,
                                ...rowData.map((count, colIndex) => (
                                    <td
                                        style={this.computeCellStyling(count, maxValue)}
                                        onClick={() => this.handleClick(rowData[colIndex], colIndex)} 
                                    >
                                        {count > 0 ? count : ''}
                                    </td>
                                )),
                                <td key={`total-${rowIndex}`} style={{ fontSize: "20px" }}>
                                    {rowData.reduce((acc, count) => acc + count, 0)}
                                </td>,
                            ]}
                        />
                    </div>
                </div>
            </PanelPage>
        );
    }

    private computeCellStyling = (value: number, maxValue: number): React.CSSProperties => {
        if (!_.isNumber(value) || value == 0) return null;
        const minL = 41;
        const maxL = 90;
        const valuePercentage = 1 - (value == 0 ? 0 : value / maxValue);
        const luminosity = (valuePercentage * (maxL - minL)) + minL;
        return { color: "white", backgroundColor: `hsl(203, 98%, ${luminosity}%)`, textAlign: "center", padding: "20px", fontSize: "20px", cursor: "pointer" };
    }

    private handleClick = (count: number, columnIndex: number) => {
        const { histogram } = this.props;
        const ids = histogram[columnIndex]; 
        this.props.queryAndShowDetailsDialogGeneric(
            '',
            state => {
                return queryEquipmentsByIds(ids)
                    .then(equipments => {
                        return equipments.map(wo => new Equipment(wo, entityFieldsDataFromState(state)))
                        
                    })
                    .catch(error => {
                        return [];
                    });
            },
            items => openListOfEntitiesOrOpenItem(item => dispatch => dispatch(showModal(ModalType.EquipmentDetails, item)), items)
        );
    }

    public componentDidMount() {
        if (isAndroid()) FirebasePlugin.setScreenName('Stopped Equipments Report Screen');
        this.props.queryStoppedEquipmentReport();
    }
}

function formatMonth(m: moment.Moment, locale: string) {
    return formatDateMonthYear(m, locale);
}

function monthsBetweenDates(dateStart: moment.Moment, dateEnd: moment.Moment, locale: string) {
    const timeValues = [];
    const dateStartClone = moment(dateStart);
    while (dateEnd > dateStartClone || dateStartClone.format('M') === dateEnd.format('M')) {
        timeValues.push(formatMonth(dateStartClone, locale));
        dateStartClone.add(1, 'month');
    }
    return timeValues;
}

export function filterWithArray<T>(ts: T[], t: T) {
    return (ts == null || ts.length <= 0 || ts.indexOf(t) > -1) && !(_.isUndefined(t));
}

export const StoppedEquipmentsReport = connect<IStoppedEquipmentReport, IStoppedEquipmentsReportDispatchProps>(
    createSelector(
        (state: IGlobalState) => state.stoppedEquipmentsReport,
        state => localeFromState(state),
        state => activeInFSMFromState(state),
        (stoppedEquipmentsReport, locale, activeInFSM) => {
            const startDate24Months = stoppedEquipmentsReport.startDate24Months;
            const startDate6Months = stoppedEquipmentsReport.startDate6Months;
            const between24and6MonthsAgo = [formatMonth(startDate24Months, locale), formatMonth(startDate6Months, locale)].join(' - ');

            const stoppedEquipmentsCountByMonth: { [key: string]: string[] } = {};

            stoppedEquipmentsReport.stoppedEquipments.forEach((equipment) => {
                const woDate = moment(equipment.FSM_Out_Of_Order_Date__c);
                const monthKey = startDate24Months.diff(woDate) <= 0 && startDate6Months.diff(woDate) >= 0 ? between24and6MonthsAgo : formatMonth(woDate, locale);

                if (!stoppedEquipmentsCountByMonth[monthKey]) {
                    stoppedEquipmentsCountByMonth[monthKey] = [];
                }
                stoppedEquipmentsCountByMonth[monthKey].push(equipment.id); 
            });

            const months = [between24and6MonthsAgo, ...monthsBetweenDates(stoppedEquipmentsReport.startDate, stoppedEquipmentsReport.endDate, locale)];
            const histogram = months.map(month => stoppedEquipmentsCountByMonth[month] || []);

            const maxValue = histogram.reduce((max, ids) => {
                const count = ids.length; 
                return count > max ? count : max; 
            }, 0);
            return { ...stoppedEquipmentsReport, stoppedEquipmentsCountByMonth, histogram, maxValue, months, locale };
        }
    ),
    {
        queryStoppedEquipmentReport,
        queryAndShowDetailsDialogGeneric,
        closeMenu
    },
)(StoppedEquipmentsClass);
