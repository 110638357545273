export const paths = {
    //ADMIN_SETTINGS: '/admin/settings',
    //ADMIN_USER_MANAGEMENT: '/admin/user-management',
    ADMIN_SETTINGS: '/app/settings',
    ADMIN_USER_MANAGEMENT: '/app/user-management',
    AUDIT: '/app/overview/audit',
    COMPLAINTS: '/app/overview/complaints',
    CUSTOMER_VISITS: '/app/overview/customer_visits',
    EQUIPMENT: '/app/overview/equipment',
    INSPECTIONS: '/app/overview/inspections',
    INSPECTION_POINTS: '/app/overview/inspection-points',
    REJECTED_SERVICE_APPOINTMENTS: '/app/overview/rejected-service-appointments',
    REJECTED_WORK_ORDERS_NON_FSM: '/app/overview/rejected-work-orders-non-fsm',
    LOCATE: '/app/locate',
    TECHNICIAN_REPORT: '/app/technician-report',
    LOGIN: '/login',
    MBM_LEFT_THIS_MONTH: '/app/overview/mbm_left_this_month',
    MONTHLY_DISCUSSIONS: '/app/overview/monthly_discussions',
    OAUTH_CALLBACK: '/oauth/callback',
    OFFLINE_ACCOUNTS: '/offline/accounts',
    OPEN_UNPLANNED_JOBS: '/app/overview/open_unplanned_jobs',

    SALES_LEADS: '/app/overview/sales-leads',
    SALES_LEADS_OWNED: '/app/overview/sales-leads-owned',
    SALES_LEADS_CUSTOMERS: '/app/overview/sales-leads-customers',

    LEADS: '/app/overview/leads',
    LEADS_OWNED: '/app/overview/leads-owned',
    LEADS_CUSTOMERS: '/app/overview/leads-customers',

    OPPORTUNITIES: '/app/overview/opportunities',
    OPPORTUNITIES_WITH_TENDERS: '/app/overview/opportunities-with-tenders',
    OPPORTUNITIES_OWNED: '/app/overview/opportunities-owned',
    OPPORTUNITIES_WITH_TENDERS_OWNED: '/app/overview/opportunities-with-tenders-owned',
    OPPORTUNITIES_CUSTOMERS: '/app/overview/opportunities-customers',
    OPPORTUNITIES_WITH_TENDERS_CUSTOMERS: '/app/overview/opportunities-with-tenders-customers',
    OVERVIEW: '/app/overview',
    PLAN: '/app/plan',
    PLAN_OFFLINE: '/offline/plan',
    QUERIES: '/app/overview/queries',
    TECHNICAL_HELPDESK_CASES: '/app/overview/technical-helpdesk-cases',
    DETRACTOR_CASES: '/app/overview/detractor-cases',
    REPAIRS: '/app/overview/repairs',
    SEARCH: '/app/search',
    SERVICE_NEEDS: '/app/overview/service-needs',
    SERVICE_ORDERS_REPORT: '/app/service-orders-report',
    STOPPED_EQUIPMENTS_REPORT: '/app/stopped-equipments-reports',
    TASKS: '/app/overview/tasks',
    ACCOUNT_REPORT: '/app/account-report',
    TRACTION_CONTROL_REPORTS: '/app/traction-control-reports',
    TRACTION_CONTROL_REPORT_VA: '/app/traction-control-report-va',
    TRACTION_CONTROL_REPORT_VC: '/app/traction-control-report-vc',
    NOTIFICATIONS: "/app/notifications",
    /*
    TENDERS: '/app/overview/tenders',
    TENDERS_BY_EQUIPMENTS: '/app/overview/tenders_by_equipments',
    */
    LINKS: "/app/links"
};
