export enum ModalType {
    AccountModal = "AccountModal",
    CalloutDetailsModal = "CalloutDetailsModal",
    ComplaintModal = "ComplaintModal",
    CreateEventQuestionModal = "CreateEventQuestionModal",
    EquipmentDetails = "EquipmentDetails",
    OpportunityModal = "OpportunityModal",
    ListOfEntitiesModal = "ListOfEntitiesModal",
    PermissionDeniedModal = "PermissionDeniedModal",
    QueryModal = "QueryModal",
    TransactionalSurveyCase = "TransactionalSurveyCase",
    ReadEventModal = "ReadEventModal",
    ReadEventOfflineModal = "ReadEventOfflineModal",
    ContactCreationModal = "ContactCreationModal",
    ContactNoteModal = "ContactNoteModal",
    ContactNoteOfflineModal = "ContactNoteOfflineModal",
    ContactPhotoModal = "ContactPhotoModal",
    ContactPhotoOfflineModal = "ContactPhotoOfflineModal",
    EditContactModal = "EditContactModal",
    EditContactOfflineModal = "EditContactOfflineModal",
    HelpdeskModal = "HelpdeskModal",
    IncompleteDialog = "IncompleteDialog",
    IncompleteEntityDialog = "IncompleteEntityDialog",
    LeaveOfflineModeModal = "LeaveOfflineModeModal",
    ShowContactModal = "ShowContactModal",
    ShowContactOfflineModal = "ShowContactOfflineModal",
    WriteEventModal = "WriteEventModal",
    WriteEventOfflineModal = "WriteEventOfflineModal",
    WriteEventAndCloseTaskModal = "WriteEventAndCloseTaskModal",
    TaskModal = "TaskModal",
    AuditTaskModal = "AuditTaskModal",
    TechnicianDialog = "TechnicianDialog",
    TenderDialog = "TenderDialog",
    MapServiceOrderLayers = "MapServiceOrderLayers",
    MapEquipmentFiltering = "MapEquipmentFiltering",
    MapOpportunityFiltering = "MapOpportunityFiltering",
    CompleteEventModal = "CompleteEventModal",
    CompleteEventOfflineModal = "CompleteEventOfflineModal",
    ConfirmModal = "ConfirmModal",
    CreateAdminAssignmentDialog = "CreateAdminAssignmentDialog",
    //SelectCompanyDialog = "SelectCompanyDialog",
    SelectOptionModal = "SelectOptionModal",
    EnterOfflineModeDialog = "EnterOfflineModeDialog",
    SelectPlannerGroupDialog = "SelectPlannerGroupDialog",
    //SelectMorePlannerGroupDialog = "SelectMorePlannerGroupDialog",
    SynchronisationSummary = "SynchronisationSummary",
    ReSelectPlannerGroupDialog = "ReSelectPlannerGroupDialog",
    SearchUserDialog = "SearchUserDialog",
    
    SearchAccountDialog = "SearchAccountDialog",

    SelectContactsDialog = "SelectContactsDialog",
    UploadCompanySettings = "UploadCompanySettings",
    WriteTaskModal = "WriteTaskModal",
    LeadModal = "LeadModal",

    WorkOrderNotesModal = "WorkOrderNotesModal"
}
