export enum SvgIcon {
    Account,
    AccountReports,
    Add,
    AddEventBar,
    AddCricle,
    AddNoteBar,
    AddPhotoBar,
    AddTaskBar,
    Adduser,
    Back,
    ButtonArrow,    
    Business,
    Calendar,
    ChevronLeft,
    ChevronRight,
    ClearBar,
    ClearBarDark,
    Close,
    Contacts,
    ContactsCopy,
    Contract,
    Date,
    Done,
    DeleteBar,
    DeleteBarCopy,
    Description,
    Edit,
    EditWhite,
    Email,
    EndDate,
    Equipment,
    Failure,
    FailureRed,
    File,
    FileDownload,
    FileDownloadWhite,
    FileDownloadBlue,
    Filter,
    Flag,
    FlagEmpty,
    Helmet,
    Info,
    Kff,
    Konect,
    Route,
    Locate,
    Location,
    LocationWhite,
    Logo,
    LogoBlue,
    Logout,
    Mail,
    MainMenuBar,
    Money,
    More,
    Note,
    Number,
    Offline,
    OfflineWhite,
    Overview,
    Parent,
    Person,
    Phone,
    Plan,
    PlanCopy2,
    Primary,
    Priority,
    Reason,
    Related,
    RejectedSmall,
    Reminder,
    ReportBar,
    Salesforce,
    Search,
    SegmentShape,
    Serviceorder,
    ServiceOrdersReport,
    Smartphone,
    Sort,
    Stairs,
    StartDate,
    Status,
    Success,
    Sync,
    Task,
    Title,
    Traction,
    User,
    Visit,
    Visittype,
    Layers,
    Completed,
    CheckmarkBar,
    Chat,
    Audit,
    WeightShape,
    Users,
    Upload,
    Appsettings,
    TechnicianReport,
    Teams,
    StoppedEquipments
}