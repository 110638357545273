import { IPayloadAction } from "../actions/actionUtils";
import { ActionStatus } from "../actions/actions";
import * as moment from 'moment';
import { QUERY_STOPPED_EQUIPMENT_REPORT } from "../actions/integration/equipmentActions";
import { StoppedEquipmentsReport } from "../models/feed/Callout";
 
const defaultStoppedEquipmentReport = {
    stoppedEquipments: [] as StoppedEquipmentsReport[],
    startDate24Months: moment().subtract(24, 'months').startOf('month'),
    startDate6Months: moment().subtract(7, 'months').endOf('month'),
    startDate: moment().subtract(6, 'months').startOf('month'),
    endDate: moment().add(3, 'months'),
    actionStatus: ActionStatus.SUCCESS
}
 
export type IStoppedEquipmentReport = typeof defaultStoppedEquipmentReport;
 
 
export function stoppedEquipmentReportReducer(state = defaultStoppedEquipmentReport, action: IPayloadAction<any>): IStoppedEquipmentReport {
    switch (action.type) {
 
        case QUERY_STOPPED_EQUIPMENT_REPORT.start: return { ...state, actionStatus: ActionStatus.START };
        case QUERY_STOPPED_EQUIPMENT_REPORT.success: return { ...state, stoppedEquipments: action.payload, actionStatus: ActionStatus.SUCCESS };
        case QUERY_STOPPED_EQUIPMENT_REPORT.failure: return { ...state, actionStatus: ActionStatus.FAILURE };
        default: return state;
    }
}
 