import * as React from 'react';
import * as cx from 'classnames';
import { Multiselect } from 'multiselect-react-dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAlphaUpAlt, faSortAlphaDownAlt } from '@fortawesome/free-solid-svg-icons';

export interface IGroup {
    title: string;
    isExtraordinary: boolean;
    style?: React.CSSProperties;
    onSelect?: (selectedList, selectedItem, type) => any;
    onRemove?: (selectedList, removedItem, type) => any;
    sortFilteredListAsc?: () => any;
    sortFilteredListDesc?: () => any;
    key?: any;
    hasEquipment?: any
}

export interface IListBodyProps<T> {
    topbar?: JSX.Element;
    emptyView?: JSX.Element;
    isLoading?: boolean;
    versionMarker?: any;
    listItems?: any[];
}

interface IAppState {
    workCenterArray: { key: string; cat: string }[];
    stopReasonArray: { key: string; cat: string }[];
    stopDateArray: { key: string; cat: string }[];
    workCenterSelectedValues: { key: string; cat: string }[];
    stopReasonSelectedValues: { key: string; cat: string }[];
    stopDateSelectedValues: { key: string; cat: string }[];
    filteredList: any[];
    isSortedAsc: boolean;
}

export class Group<T> extends React.Component<IGroup & IListBodyProps<T>, IAppState> {

    constructor(props) {
        super(props);
        this.state = {
            workCenterArray: [],
            stopReasonArray: [],
            stopDateArray: [],
            workCenterSelectedValues: [],
            stopReasonSelectedValues: [],
            stopDateSelectedValues: [],
            filteredList: [],
            isSortedAsc: false
        };
    }


    populateArray() {
        let dateOnly;
        if (this.props.listItems && this.props.listItems.length > 0 && this.props.listItems[0].items) {
            const seenKeys = new Set();
            const workCenterArray = this.props.listItems[0].items.reduce((acc, item, index) => {
                const key = item.value?.Main_Work_Center__r?.Name ?? `Default Key ${index}`;
                if (!seenKeys.has(key)) {
                    seenKeys.add(key);
                    acc.push({ key, cat: `Group ${index + 1}` });
                }
                return acc;
            }, []);
            const stopReasonArray = this.props.listItems[0].items.reduce((acc, item, index) => {
                const key = item.value?.Equipment__r?.Out_of_order_reason__c;
                if (key && !seenKeys.has(key)) {
                    seenKeys.add(key);
                    acc.push({ key, cat: `Group ${index + 1}` });
                }
                return acc;
            }, []);
            const stopDateArray = this.props.listItems[0].items.reduce((acc, item, index) => {
                const key = item.value?.FSM_Out_Of_Order_Date__c;
                if (key) {
                    const date = new Date(key);
                    const day = String(date.getDate()).padStart(2, '0');
                    const month = String(date.getMonth() + 1).padStart(2, '0'); 
                    const year = date.getFullYear();
                    dateOnly = `${year}-${month}-${day}`;
                    if (dateOnly && !seenKeys.has(dateOnly)) {
                        seenKeys.add(dateOnly);
                        acc.push({ key: dateOnly, cat: `Group ${index + 1}` });
                    }
                }
                return acc;
            }, []);
            const workCenterSelected = this.state.workCenterSelectedValues.map(selected => selected.key);
            const stopReasonSelected = this.state.stopReasonSelectedValues.map(selected => selected.key);
            const stopDateSelected = this.state.stopDateSelectedValues.map(selected => selected.key);
            const filteredList = this.props.listItems[0].items.filter(item => {
                const workCenterMatch = workCenterSelected.length > 0 ? workCenterSelected.includes(item.value?.Main_Work_Center__r?.Name) : true;
                const stopReasonMatch = stopReasonSelected.length > 0 ? stopReasonSelected.includes(item.value?.Equipment__r?.Out_of_order_reason__c) : true;
                const stopDateMatch = stopDateSelected.length > 0 ? stopDateSelected.includes(dateOnly) : true;
                return workCenterMatch && stopReasonMatch && stopDateMatch;
            });

            this.setState({ workCenterArray, stopReasonArray, stopDateArray, filteredList });
        }
    }

    componentDidMount() {
        this.populateArray();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.listItems !== this.props.listItems) {
            this.populateArray();
        } else if (prevState.filteredList !== this.state.filteredList) {
            this.setState({
                filteredList: this.state.filteredList
            });
        }
    }


    public render() {
        const title = this.props.title;
        return <>
            {title && this.props.hasEquipment ?
                (
                    <>
                        <div className="row base-container">
                            <div key={title} className='col-xs-6 col-sm-3 title-container'>
                                <div className={cx('list-item subheader', { 'subheader--alert': this.props.isExtraordinary })}>{title}</div>
                                {this.props.children}
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="sort-multiselect-frame">
                                    
                                        <button
                                            onClick={this.props.sortFilteredListAsc}
                                            className="button-sort"
                                            aria-label="Sort Ascending"
                                        >
                                            <FontAwesomeIcon icon={faSortAlphaUpAlt} />
                                        </button>
                                    
                                    <Multiselect
                                        key={this.props.key}
                                        options={this.state.stopDateArray}
                                        displayValue="key"
                                        showCheckbox={true}
                                        selectedValues={this.state.stopDateSelectedValues}
                                        className="custom-multiselect multiselect-1"
                                        placeholder="stop date"
                                        onSelect={(selectedList, selectedItem) => this.props.onSelect(selectedList, selectedItem, 'stopdate')}
                                        onRemove={(selectedList, removedItem) => this.props.onRemove(selectedList, removedItem, 'stopdate')}
                                    />
                                    
                                        <button
                                            onClick={this.props.sortFilteredListDesc}
                                            className="button-sort"
                                            aria-label="Sort Descending"
                                        >
                                            <FontAwesomeIcon icon={faSortAlphaDownAlt} />
                                        </button>
                                </div>
                            </div>
                        </div>
                        <div className="row base-container">
                            <div className='col-xs-6 col-sm-3'>
                                <Multiselect
                                    key={this.props.key}
                                    options={this.state.stopReasonArray}
                                    displayValue="key"
                                    showCheckbox={true}
                                    selectedValues={this.state.stopReasonSelectedValues}
                                    className="custom-multiselect"
                                    placeholder="stop reason"
                                    onSelect={(selectedList, selectedItem) => this.props.onSelect(selectedList, selectedItem, 'stopreason')}
                                    onRemove={(selectedList, removedItem) => this.props.onRemove(selectedList, removedItem, 'stopreason')}
                                />
                            </div>
                            <div className='col-xs-6 col-sm-3'>
                                <Multiselect
                                    key={this.props.key}
                                    options={this.state.workCenterArray}
                                    displayValue="key"
                                    showCheckbox={true}
                                    selectedValues={this.state.workCenterSelectedValues}
                                    className="custom-multiselect multiselect-2"
                                    placeholder="workcenter"
                                    onSelect={(selectedList, selectedItem) => this.props.onSelect(selectedList, selectedItem, 'workcenter')}
                                    onRemove={(selectedList, removedItem) => this.props.onRemove(selectedList, removedItem, 'workcenter')}
                                />
                            </div>
                        </div></>
                ) : (<div key={title} style={this.props.style}>
                    <div className={cx('list-item subheader', { 'subheader--alert': this.props.isExtraordinary })}>{title}</div>
                    {(this.props as any).children}</div>)}
        </>
    }
}