import { itemsStateAsyncActionNames, payloadAction } from '../actionUtils';
import { getJsforceCollectionAction } from '../http/jsforce';
import { queryWorkOrdersBase } from '../http/jsforceBase';
import { conditions } from '../http/jsforceMappings';
import { plannerGroupIdFromState, activeInFSMFromState } from '../../models/globalState';
import * as fields from "../http/queryFields";
import { Moment } from 'moment';
import { ServiceOrderReportTab } from '../../reducers/serviceOrdersReportReducer';
import { WorkOrderForServiceOrdersReport } from '../../models/api/coreApi';
import { WorkOrderServiceOrderReport } from '../../models/feed/Callout';
import { multiQuery1Extract, multiQuery1, joinQueries } from '../http/jsforceCore';
import * as _ from 'lodash';
import { isAndroid, firebaseStartTrace, firebaseStopTrace } from 'src/utils/cordova-utils';
import { ThunkAction } from '../thunks';
import * as moment from 'moment';

export const QUERY_SERVICE_ORDERS_REPORT = itemsStateAsyncActionNames('QUERY_SERVICE_ORDERS_REPORT');

export const SELECT_SERVICE_ORDERS_REPORT_TAB = 'SELECT_SERVICE_ORDERS_REPORT_TAB';
export const SELECT_SERVICE_ORDERS_REPORT_TYPES = 'SELECT_SERVICE_ORDERS_REPORT_TYPES';
export const SELECT_SERVICE_ORDERS_REPORT_WORKCENTERS = 'SELECT_SERVICE_ORDERS_REPORT_WORKCENTERS';

export const SET_ERROR_MESSAGE_PLANNERGROUP = 'SET_ERROR_MESSAGE_PLANNERGROUP';
// function queryServiceOrdersForReport(serviceTerritoryId: string, activeInFSM: boolean, startDate: Moment, endDate: Moment) {
//     const { earliestStartDateBetween, serviceTerritory, open, notSiteVisitWorkOrder, Y99, endDateBetween } = conditions.WorkOrder;
//     return multiQuery1Extract(queryWorkOrdersBase<WorkOrderForServiceOrdersReport>(
//         [serviceTerritory(serviceTerritoryId), endDateBetween(startDate, endDate), open(activeInFSM), notSiteVisitWorkOrder, Y99.negate() ], fields.IWorkOrderForServiceOrdersReport).sort('Earliest_Start_Date__c', 'ASC')
//     );
// }
async function queryServiceOrdersForReport(serviceTerritoryId: string, activeInFSM: boolean, startDate: Moment, endDate: Moment) {
    const { earliestStartDateBetween, serviceTerritory, open, openFSMandKonect, notSiteVisitWorkOrder, Y99, endDateBetween, earliestCreatedDateAfter } = conditions.WorkOrder;
    let allWos: WorkOrderForServiceOrdersReport[] = [];
    let lastCreatedDate: Moment | null = null;  

    while (true) {
        const batchSize = 2000; 
        const queryConditions = [
            serviceTerritory(serviceTerritoryId),
            endDateBetween(startDate, endDate),
            openFSMandKonect,
            notSiteVisitWorkOrder,
            Y99.negate()
        ];

        if (lastCreatedDate) {
            queryConditions.push(earliestCreatedDateAfter(lastCreatedDate) as any);
        }

        const multiQueryResult = await multiQuery1(queryWorkOrdersBase<WorkOrderForServiceOrdersReport>(
            queryConditions,
            fields.IWorkOrderForServiceOrdersReport,
        )
        .sort('CreatedDate', 'ASC')
        .limit(batchSize));

        const batchWos: WorkOrderForServiceOrdersReport[] = multiQueryResult.queries.result[0];

        if (batchWos.length === 0) {
            break;
        }

        lastCreatedDate = moment(batchWos[batchWos.length - 1].CreatedDate);

        allWos.push(...batchWos);
    }
    return allWos;
}

export const queryServiceOrdersReport = (): ThunkAction<void> => async (dispatch, getState) => {
    if(isAndroid()){ firebaseStartTrace('Open Work Orders Report trace') }

    let state = getState();
    if(state === null || plannerGroupIdFromState(state) === null) {
        dispatch(SetErrorPlannergroup('PlannerGroup is null, please relog'));
        return;
    }
    const actionToDispatch = getJsforceCollectionAction(
        state => queryServiceOrdersForReport(
            plannerGroupIdFromState(state), activeInFSMFromState(state), state.serviceOrdersReport.startDate24Months, state.serviceOrdersReport.endDate
        ),
        WorkOrderServiceOrderReport, QUERY_SERVICE_ORDERS_REPORT
    );
    dispatch(actionToDispatch);
}

export function selectServiceOrdersReportTab(tab: ServiceOrderReportTab) {
    return payloadAction(SELECT_SERVICE_ORDERS_REPORT_TAB)(tab);
}

export function selectServiceOrderTypes(types: string[]) {
    return payloadAction(SELECT_SERVICE_ORDERS_REPORT_TYPES)(types);
}

export function selectServiceOrderWorkcenters(workcenters: string[]) {
    return payloadAction(SELECT_SERVICE_ORDERS_REPORT_WORKCENTERS)(workcenters);
}

export function SetErrorPlannergroup(plannerGroup: string) {
    return payloadAction(SET_ERROR_MESSAGE_PLANNERGROUP)(plannerGroup);
}