import * as cx from 'classnames';
import * as React from 'react';
import { save } from 'src/utils/fileUtils';
import * as XLSX from 'xlsx';
import excelButton from '../../images/icon-excel.png';

export interface ITabsProps {
    items: JSX.Element[];
    selectedTab: number;
    onChangeTab: (index: number) => any;
    className?: string;
    operationData?: any;
    isExcel?: any;
}

export default class Tabs extends React.Component<ITabsProps, object> {

    public render() {

        const handleDownload = () => {
            const operations = this.props.operationData.map(operation => ({
                Operations: operation.value.Operation_Text__c
            }))
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(operations);
            const columnWidths = [
                { wch: 60}
            ];
            ws['!cols'] = columnWidths;
            XLSX.utils.book_append_sheet(wb, ws, 'Operations');
            const excelBuffer = XLSX.write(wb, { type: 'array', bookType: 'xlsx' });
            const content = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const filename = `Operations_Report_${Date.now()}.xlsx`;
            save(filename, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', content, (key, error) => {
                console.error(`Error saving file: ${key}`, error);
            });
        }
        //const transform = `translateX(${ this.props.selectedTab }00%)`;
        const width = `${ 100 / this.props.items.length }%`;
        return (
            <div className={cx('tabs__group', this.props.className)}>
                {this.props.items.map((item, index) => (
                    <div className={cx('container--centered', 'tabs__tab', { 'tabs__tab--unselected': index !== this.props.selectedTab })}
                        style={{ width }} onClick={this.handleClick(index)} key={index}>
                        {item}
                        {this.props.isExcel === 'true' && index === 1 &&
                        <button className='excel-button' onClick={handleDownload}>
                            <img src={excelButton} alt='Excel Icon' className='excel-icon' />
                        </button>
                        }
                    </div>
                ))}
                <div className={`tabs__indicator tabs__indicator-${this.props.selectedTab}`}
                    style={{ //WebkitTransform: transform, transform,
                        width }} />
            </div>
        );
    }

    private handleClick = (index: number) => () => {
        if (index === this.props.selectedTab) return;
        this.props.onChangeTab(index);
    };
}